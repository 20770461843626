import { instance } from "../../../../instances/configImport";

//const isCEG = instance.includes("isCEG");
const menuItems = [
	{
		key: "TopMenu",
		display: "Top Menu",
	},
	{
		key: "LegalMenu",
		display: "Legal Menu",
	},
	{
		key: "MainMenu",
		display: "Main Menu",
	},
	{
		key: "FooterMenu",
		display: "Footer Menu",
	},
];

//if (isCEG) {
menuItems.push({
	key: "AlumniMenu",
	display: "Alumni Menu",
});
//}

export default menuItems;
